import { ProductDAO } from "../data/productDAO"

export const ProductService = {
    getByBarCode: async (cid, barCode) => {
        try {
            const response = await ProductDAO.getByBarCode(cid, barCode)

            return response
        } catch (error) {
            console.error("Error al obtener el artículo", error);
            throw new Error("No se pudo obtener el artículo. Por favor, intenta de nuevo.");
        }
    }
}