import axios from "axios";

export const ProductDAO = {
    getByBarCode: async (cid, barCode) => {
        try {
          const response = await axios.get(
            `api/Articulos/getArticuloByCodigoBarras?cia=${cid}&codigoBarras=${barCode}`, 
            { crossdomain: true }
          );

          return response.data;
        } catch (error) {
          console.error("Error al obtener el artículo por código de barras:", error);
          throw new Error("No se pudo obtener el artículo. Por favor, intenta de nuevo.");
        }
      }
      
}