<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Seleccionar Articulo
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Búsqueda"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-simple-checkbox v-model="item.type" disabled> </v-simple-checkbox>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn small @click="selected(item)"> Seleccionar </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    allwedOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Código",
          align: "start",
          value: "codigo",
        },
        {
          text: "Descripción",
          align: "start",
          value: "nombre",
        },
        {
          text: "CódigoCabys",
          align: "start",
          value: "cabys",
        },
        {
          text: "Precio",
          align: "start",
          value: "monto",
        },
        { text: "Acciones", value: "actions", width: 100, sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        codigo: "",
        nombre: "",
        tipoArt: "",
        unidad: "",
        tarifa: 0,
        monto: 0,
        barras: "",
        existencia: 0,
        costo: 0,
        peso: "",
        codigoImp: "",
        codigoTarifa: "",
        cabys: "",
        cia: "",
        registroMedicamento: "",
        formaFarmaceutica: "",
      },
      defaultItem: {
        codigo: "",
        nombre: "",
        tipoArt: "",
        unidad: "",
        tarifa: 0,
        monto: 0,
        barras: "",
        existencia: 0,
        costo: 0,
        peso: "",
        codigoImp: "",
        codigoTarifa: "",
        cabys: "",
        cia: "",
        registroMedicamento: "",
        formaFarmaceutica: "",
      },
    };
  },

  computed: {
    ...mapGetters(["strCia"]),
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Articulos/getArticulosByCompany?cia=" + this.strCia, {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    selected(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$emit("clicked", this.editedItem);
    },

    close() {
      this.dialog = false;
      this.$emit("closed");
    },
  },
};
</script>