<template>
  <div class="renderPage" dark>
    <v-toolbar color="primary" elevation="1">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Seleccionar Codigo Cabys
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-expansion-panels multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="9">
                <v-text-field v-model="cabysDescripcion" label="Buscar por código ó descripción"
                  required></v-text-field>
              </v-col>

              <v-col align="center" cols="12" sm="6" md="3">
                <v-btn color="primary" dark class="mb-2" @click="getData">
                  Buscar
                </v-btn>
              </v-col>

              <v-col align="center" cols="12" sm="12" md="12">
                <v-data-table :headers="headers" :items="items" :search="search">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrar resultados" single-line
                        hide-details></v-text-field>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.type`]="{ item }">
                    <v-simple-checkbox v-model="item.type" disabled>
                    </v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn small @click="selected(item)"> Seleccionar </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- <template>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="9">
            <v-text-field
              v-model="cabysDescripcion"
              label="Buscar por descripción"
              required
            ></v-text-field>
          </v-col>

          <v-col align="center" cols="12" sm="6" md="3">
            <v-btn color="primary" dark class="mb-2" @click="getData">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template> -->
    <!-- 
    <v-container>
      <v-row align="center" justify="space-around"> </v-row>
    </v-container> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    allwedOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      search: "",
      panel: [],
      headers: [
        {
          text: "Código Cabys",
          align: "start",
          value: "codigo",
          width: 100,
        },
        {
          text: "Descripción",
          align: "start",
          value: "descripcion",
        },
        {
          text: "% Impuesto",
          align: "start",
          value: "impuesto",
          width: 150,
        },
        { text: "Acciones", value: "actions", width: 100, sortable: false },
      ],
      items: [],
      editedIndex: -1,
      cabysDescripcion: "",
      editedItem: {
        codigo: "",
        descripcion: "",
        impuesto: "",
      },
      defaultItem: {
        codigo: "",
        descripcion: "",
        impuesto: "",
      },
    };
  },

  created() {
    this.panel = [0];
    //this.getData();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get(
          "https://api.hacienda.go.cr/fe/cabys?q=" +
          this.cabysDescripcion
        )
        .then(function (response) {
          if (response.data && response.data.cabys && response.data.cabys.length > 0) {
            me.items = response.data.cabys;
          } else {
            me.getData2(); 
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getData2() {
      let me = this;
      axios
        .get(
          "https://api.hacienda.go.cr/fe/cabys?codigo=" +
          this.cabysDescripcion
        )
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    selected(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$emit("clicked", this.editedItem);
    },

    close() {
      this.dialog = false;
      this.$emit("closed");
    },
  },
};
</script>